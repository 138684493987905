import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { updateConfig } from '../reducers/configActions';
import { updateCam, deleteCam, moveCam } from '../reducers/camsActions';
import ChannelSwitch from './ChannelSwitch';
import Modal from '../components/Modal';
import './../styles/cam.scss';
import { words } from 'lodash';
import classNames from 'classnames';
import OverlaySelect from './OverlaySelect';

type Props = {
  name: string,
  cam: Object,
  flippedProps: Object,
  onEdit: Function,
};

type State = {
  isMoveModalOpen: boolean,
  isDeleteModalOpen: boolean,
  moveOption: string,
};

const CHANNEL_NAME_TO_SDI = {
  'CH 1': 'Sdi0',
  'CH 2': 'Sdi1',
  'CH 3': 'Sdi2',
  'CH 4': 'Sdi3',
};
class CamPanel extends React.Component {
  state: State = {
    isMoveModalOpen: false,
    isDeleteModalOpen: false,
    moveOption: this.props.name,
  };

  onDisabledChange = newState => {
    this.props.updateCam({
      [this.props.name]: { ...this.props.cam, disabled: newState },
    });
  };

  onChannelSwitch = channelName => {
    const sdi = CHANNEL_NAME_TO_SDI[channelName];
    if (this.props.name == 'Black') {
      this.props.updateConfig(sdi, { output: 'black', alphaimage: 'none' });
    } else {
      this.props.updateConfig(sdi, {
        output: 'url',
        alphaimage: this.props.cam.alphaimage,
        url: this.buildFullUrl(this.props.cam.url),
        port: this.props.cam.port,
        transport: this.props.cam.transport,
      });
    }
  };

  buildFullUrl = baseURL => {
    if ((this.props.cam.password || this.props.cam.username) && baseURL.indexOf('@') == -1) {
      const splitted = baseURL.split('://');
      if (splitted.length != 2) {
        return baseURL;
      } else {
        return (
          splitted[0] +
          '://' +
          this.props.cam.username +
          ':' +
          this.props.cam.password +
          '@' +
          splitted[1]
        );
      }
    } else {
      return baseURL;
    }
  };

  getChannelState = sdi => {
    const ch = this.props[sdi];

    if (this.props.name == 'Black') {
      return ch['output'] !== 'url' ? ch['pipeline-state'].toLowerCase() : 'off';
    }

    if (
      ch['url'] == this.buildFullUrl(this.props.cam.url) &&
      ch['output'] == 'url'
    ) {
      return ch['pipeline-state'].toLowerCase();
    }
    return 'off';
  };

  onPanelMove = () => {
    if (this.props.name != this.state.moveOption) {
      this.props.moveCam(this.props.name, this.state.moveOption);
    }
    this.setState({ isMoveModalOpen: false });
  };

  onDelete = () => {
    this.props.deleteCam(this.props.name);
  };

  onEdit = () => {
    this.props.onEdit(this.props.name, this.props.cam);
  };

  compareCams = (name1, name2) => {
    return this.props.cams[name1].id - this.props.cams[name2].id;
  };

  render() {
    const { disabled } = this.props.cam;
    return (
      <div
        {...this.props.flippedProps}
        className="card card-body bg-light col-sm-8 m-3 flex-row justify-content-between"
      >
        <div className="col-sm-3 d-flex flex-column align-items-center justify-content-center">
          <p className={classNames('cam-panel-title', disabled && 'text-muted')}>
            {truncateLongWords(this.props.name)}
          </p>
          {this.props.name != 'Black' &&
            this.props.cam.ctl_url != null &&
            this.props.cam.ctl_url.length != 0 && (
              <a
                href={this.props.cam.ctl_url}
                target="_blank"
                className={'btn btn-primary px-4 text-white' + (disabled ? ' disabled' : '')}
              >
                ctl
              </a>
            )}
        </div>

        <div className="row col-sm-6 align-items-center">
          <ChannelSwitch
            channelName="CH 1"
            channelState={this.getChannelState('Sdi0')}
            onClick={this.onChannelSwitch}
            disabled={disabled}
          />
          <ChannelSwitch
            channelName="CH 2"
            channelState={this.getChannelState('Sdi1')}
            onClick={this.onChannelSwitch}
            disabled={disabled}
          />
          <ChannelSwitch
            channelName="CH 3"
            channelState={this.getChannelState('Sdi2')}
            onClick={this.onChannelSwitch}
            disabled={disabled}
          />
          <ChannelSwitch
            channelName="CH 4"
            channelState={this.getChannelState('Sdi3')}
            onClick={this.onChannelSwitch}
            disabled={disabled}
          />
        </div>

        {this.props.name == 'Black' ? (
          <div className="col-sm-3" />
        ) : (
          <div className="col-sm-3 d-flex flex-column align-items-center justify-content-center">
            <OverlaySelect
              name={this.props.name}
              cam={this.props.cam}
              getChannelState={this.getChannelState}
              disabled={disabled}
            />
            <div className="dropdown">
              <button
                className="btn dropdown-toggle border-0 bg-transparent"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ...
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                x-placement="bottom-start"
                style={{
                  position: 'absolute',
                  willChange: 'transform',
                  top: '0px',
                  left: '0px',
                  transform: 'translate3d(0px, 36px, 0px)',
                }}
              >
                <div onClick={this.onEdit} className="dropdown-item btn">
                  Edit
                </div>
                {disabled ? (
                  <div className="dropdown-item btn" onClick={() => this.onDisabledChange(false)}>
                    Enable
                  </div>
                ) : (
                  <div className="dropdown-item btn" onClick={() => this.onDisabledChange(true)}>
                    Disable
                  </div>
                )}
                <div
                  onClick={() => this.setState({ isDeleteModalOpen: true })}
                  className="dropdown-item btn"
                >
                  Delete
                </div>
                <div
                  onClick={() => this.setState({ isMoveModalOpen: true })}
                  className="dropdown-item btn"
                >
                  Move
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          isOpen={this.state.isDeleteModalOpen}
          title={'Delete Panel ' + this.props.name}
          onSubmit={this.onDelete}
          onClose={() => this.setState({ isDeleteModalOpen: false })}
        >
          {this.renderDeleteModalContent()}
        </Modal>
        <Modal
          isOpen={this.state.isMoveModalOpen}
          title={'Move Panel ' + this.props.name}
          onSubmit={this.onPanelMove}
          submitText={'Move below'}
          onClose={() => this.setState({ isMoveModalOpen: false })}
        >
          {this.renderMoveModalContent()}
        </Modal>
      </div>
    );
  }

  renderDeleteModalContent() {
    if (!this.state.isDeleteModalOpen) {
      return null;
    } else {
      return (
        <div>
          <p className="text-danger mt-3">Are you sure?</p>
        </div>
      );
    }
  }

  renderMoveModalContent() {
    if (!this.state.isMoveModalOpen) {
      return null;
    } else {
      const panelsList = ['Black', ...Object.keys(this.props.cams).sort(this.compareCams)];
      const panels = panelsList.map(panel => (
        <option key={panel} value={panel}>
          {panel}
        </option>
      ));

      return (
        <div>
          <p className="text-info mt-3">Select a new position for the Panel:</p>
          <select
            className="form-control m-2"
            onChange={e => this.setState({ moveOption: e.target.value })}
            value={this.state.moveOption}
          >
            {panels}
          </select>
        </div>
      );
    }
  }
}

function truncateLongWords(text: string): string {
  const MaxLen = 12;
  return words(text)
    .map((word: string) => {
      if (word.length < MaxLen) {
        return word;
      }

      return word.slice(0, MaxLen) + '\u2026';
    })
    .join(' ');
}

const mapStateToProps = (state, props) => {
  return {
    Sdi0: state.config.getIn(['data', 'Sdi0'], Map()).toJS(),
    Sdi1: state.config.getIn(['data', 'Sdi1'], Map()).toJS(),
    Sdi2: state.config.getIn(['data', 'Sdi2'], Map()).toJS(),
    Sdi3: state.config.getIn(['data', 'Sdi3'], Map()).toJS(),
    networkInterfaces: state.config.getIn(['data', 'Ports'], List()).toJS(),
    cams: state.cams.getIn(['data', 'PlusCams'], Map()).toJS(),
    isReady: !!state.config.get('data') && !state.config.get('isFetchPending'),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateConfig: (channelId, channelUpdates) =>
      dispatch(
        updateConfig({
          [channelId]: channelUpdates,
        })
      ),
    deleteCam: name => dispatch(deleteCam(name)),
    updateCam: camSpec => dispatch(updateCam(camSpec)),
    moveCam: (panelName, targetName) => dispatch(moveCam(panelName, targetName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CamPanel);
