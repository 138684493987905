// @flow

import type { AxiosError, AxiosPromise } from 'axios';
import type { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';
import { get } from 'lodash';

export type UiFetchOptions<T = any> = {
  loadingAction: string,
  successAction: string,
  successArgs?: (res: T) => any,
  failureAction: string,

  successDesc?: string,
  failureDesc: string,
  failureToastFilter?: (err: AxiosError<T>) => boolean,

  onSuccess?: ThunkAction<void, any, T>,
  onFailure?: ThunkAction<void, any, AxiosError<T>>,
}

export default function uiFetchThunk<T = any>(
  prom: (getState?: () => any) => AxiosPromise<T>,
  {
    loadingAction,
    successAction,
    successArgs,
    failureAction,
    successDesc,
    failureDesc,
    failureToastFilter,
    onSuccess,
    onFailure,
  }: UiFetchOptions<T>,
): ThunkAction<Promise<T>> {
  return (dispatch, getState) => {
    dispatch({ type: loadingAction });
    return prom(getState)
      .then(res => {
        dispatch({ type: successAction, ...(successArgs ? successArgs(res.data) : {}) });

        if (successDesc) {
          toast.success(`Successfully ${successDesc}`);
        }

        if (onSuccess) {
          onSuccess(dispatch, getState, res.data);
        }

        return res && res.data;
      })
      .catch(error => {
        if (!error.response) {
          dispatch({ type: failureAction, timeout: true, error });
          toast.error(`Request to ${failureDesc} timed-out`);
        } else {
          dispatch({ type: failureDesc, error });
          if (failureToastFilter ? failureToastFilter(error) : true) {
            const statusCode = get(error, 'response.statusText');
            toast.error(`Request to ${failureDesc} failed with Error[${statusCode}]`);
          }
        }

        if (onFailure) {
          onFailure(dispatch, getState, error);
        }
      });
  };
}
