import { Map } from 'immutable';
import * as _ from 'lodash';

export const actionType = {
  NETWORK_FETCH: 'NETWORK_FETCH',
  NETWORK_FETCH_SUCCESS: 'NETWORK_FETCH_SUCCESS',
  NETWORK_FETCH_ERROR: 'NETWORK_FETCH_ERROR',
  NETWORK_UPDATE: 'NETWORK_UPDATE',
  NETWORK_UPDATE_SUCCESS: 'NETWORK_UPDATE_SUCCESS',
  NETWORK_UPDATE_ERROR: 'NETWORK_UPDATE_ERROR',
};

const ACTION_HANDLERS = {
  [actionType.NETWORK_FETCH]: (state, action) => {
    return state.merge({ isFetchPending: true });
  },
  [actionType.NETWORK_FETCH_ERROR]: (state, action) => {
    return state.merge({
      isFetchPending: false, 
      fetchErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.NETWORK_FETCH_SUCCESS]: (state, action) => {
    return state.merge({
      isFetchPending: false,
      fetchErrors: null,
      data: action.networks,
    });
  },
  [actionType.NETWORK_UPDATE]: (state, action) => {
    return state.merge({ isUpdatePending: true });
  },
  [actionType.NETWORK_UPDATE_ERROR]: (state, action) => {
    return state.merge({
      isUpdatePending: false, 
      updateErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.NETWORK_UPDATE_SUCCESS]: (state, action) => {
    return state.merge({
      isUpdatePending: false,
      updateErrors: null,
      data: {
        ...state.get('data').toJS(),
        [action.iface]: action.network,
      },
    });
  },
};


const initialState = Map();
export const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
