import {
  combineReducers,
  createStore as createReduxStore,
  applyMiddleware,
} from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { reducer as configReducer } from './config';
import { reducer as networkReducer } from './network';
import { reducer as camsReducer } from './cams';
import { fetchConfig } from './configActions';
import { fetchNetwork } from './networkActions';
import { fetchCams } from './camsActions';
import api from '../api';


export function createStore() {
  const logger = createLogger({
      stateTransformer: (state: Object) => _.mapValues(state, e => (e.toJS ? e.toJS() : e)),
  });

  const store = createReduxStore(
    combineReducers({
      config: configReducer,
      network: networkReducer,
      cams: camsReducer,
    }),
    {},
    applyMiddleware(thunk, logger),
  );

  onStoreCreated(store)
    .catch(error => {
      console.error('Failed to initialize store: ', error);
    });

  return store;
}

async function onStoreCreated(store) {
  await api.getSecurity();
  await store.dispatch(fetchConfig());
  await store.dispatch(fetchNetwork());
  await store.dispatch(fetchCams());
}
