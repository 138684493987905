import { Map, fromJS } from 'immutable';
import * as _ from 'lodash';

export const actionType = {
  CONFIG_FETCH: 'CONFIG_FETCH',
  CONFIG_PUSH: 'CONFIG_PUSH',
  CONFIG_FETCH_SUCCESS: 'CONFIG_FETCH_SUCCESS',
  CONFIG_FETCH_ERROR: 'CONFIG_FETCH_ERROR',
  CONFIG_UPDATE: 'CONFIG_UPDATE',
  CONFIG_UPDATE_SUCCESS: 'CONFIG_UPDATE_SUCCESS',
  CONFIG_UPDATE_ERROR: 'CONFIG_UPDATE_ERROR',
};

const ACTION_HANDLERS = {
  [actionType.CONFIG_FETCH]: (state, action) => {
    return state.merge({ isFetchPending: true });
  },
  [actionType.CONFIG_FETCH_ERROR]: (state, action) => {
    return state.merge({
      isFetchPending: false,
      fetchErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.CONFIG_FETCH_SUCCESS]: (state, action) => {
    return state.merge({
      isFetchPending: false,
      fetchErrors: null,
      data: action.config,
    });
  },
  [actionType.CONFIG_PUSH]: (state, action) => {
    return state.mergeDeepIn(['data'], action.obj);
  },
  [actionType.CONFIG_UPDATE]: (state, action) => {
    return state.merge({ isUpdatePending: true });
  },
  [actionType.CONFIG_UPDATE_ERROR]: (state, action) => {
    return state.merge({
      isUpdatePending: false,
      updateErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.CONFIG_UPDATE_SUCCESS]: (state, action) => {
    const data = _.merge(state.get('data').toJS(), action.config);
    return state.merge({
      isUpdatePending: false,
      updateErrors: null,
      data,
    });
  },
};

const initialState = Map();
export const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
