import { actionType } from './network';
import api from '../api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import uiFetchThunk from '../api/ui';

export function fetchNetwork() {
  return uiFetchThunk(api.getNetwork, {
    loadingAction: actionType.NETWORK_FETCH,
    successAction: actionType.NETWORK_FETCH_SUCCESS,
    successArgs: networks => ({ networks }),
    failureAction: actionType.NETWORK_FETCH_ERROR,
    failureDesc: 'fetch V-Streamer config',
  });
}

// some network changes are not applied immediately and followed by a reboot, so there
// is no reason to fetch new settings right after submitting (fetchAfterUpdate param)
export function updateNetwork(iface, network, fetchAfterUpdate = true) {
  return uiFetchThunk(
    getState => {
      const networks = getState().network.get('data').toJS();
      return api.updateNetwork({ ...networks, [iface]: network });
    },
    {
      loadingAction: actionType.NETWORK_UPDATE,
      successAction: actionType.NETWORK_UPDATE_SUCCESS,
      successArgs: () => ({ network, iface }),
      failureAction: actionType.NETWORK_UPDATE_ERROR,
      successDesc: 'updated network settings',
      failureDesc: 'fetch V-Streamer config',

      onSuccess(dispatch) {
        if (fetchAfterUpdate) {
          dispatch(fetchNetwork());
        }
      },

      onFailure(dispatch) {
        dispatch(fetchNetwork());
      },
    });
}
