import React from 'react';
import classNames from 'classnames';
import './../styles/cam.scss';

export type ChannelSwitchProps = {
  channelName: string,
  channelState: string,
  disabled?: boolean,
  onClick: (string) => void,
}

export default function ChannelSwitch({
  channelName,
  channelState,
  disabled,
  onClick,
}: ChannelSwitchProps) {
  return (
    <div className="col-sm-3 p-1">
      <div
        className={classNames(
          'square',
          `bg-channel-switch-${channelState}`,
          disabled && ' square-disabled',
        )}
      >
        <div onClick={() => !disabled && onClick(channelName)}>
          <div>
            <div>{channelName}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
