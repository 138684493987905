import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { fetchCams } from '../reducers/camsActions';
import { Map } from 'immutable';
import CamPanel from './CamPanel';
import { fetchConfig } from '../reducers/configActions';
import AddCamForm from './AddCamForm';
import { Flipper, Flipped } from 'react-flip-toolkit';
import classNames from 'classnames';

class PlusPage extends React.Component {
  state = {
    isModalOpen: false,
    editedName: null,
    editedData: null,
  };

  onCancelModal = () => this.setState({ isModalOpen: false, editedName: null, editedData: null });
  onOpenModalButton = () =>
    this.setState({ isModalOpen: true, editedName: null, editedData: null });

  onEdit = (name, data) => {
    this.setState({
      isModalOpen: true,
      editedName: name,
      editedData: data,
    });
  };

  checkLicense = () => {
    return this.props.plusLicenseStatus === 'valid';
  };

  compareCams = (name1, name2) => {
    return this.props.cams[name1].id - this.props.cams[name2].id;
  };

  render() {
    if (this.props.isConfigReady && !this.checkLicense()) {
      window.location.href = window.location.origin + '/settings';
      return null;
    }

    if (!this.props.isReady) {
      return null;
    }

    const camNames = Object.keys(this.props.cams).sort(this.compareCams);
    const canAddCams = camNames.length < this.props.panelsLimit;

    const cams = camNames.map(name => (
      <Flipped key={name} flipId={name}>
        {flippedProps => (
          <CamPanel
            name={name}
            cam={this.props.cams[name]}
            onEdit={this.onEdit}
            flippedProps={flippedProps}
          />
        )}
      </Flipped>
    ));
    return (
      <Flipper flipKey={camNames.join('-')} className="d-flex flex-column align-items-center">
        <CamPanel name="Black" cam={{}} />
        {cams}
        <FaPlusCircle
          className={classNames('btn', !canAddCams && 'disabled')}
          color="#007bff"
          size="5em"
          title={
            canAddCams
              ? 'Add new panel'
              : 'Cannot add new panels, because the panel limit has been reached.'
          }
          onClick={canAddCams ? this.onOpenModalButton : null}
        />
        <AddCamForm
          visible={this.state.isModalOpen}
          onCancel={this.onCancelModal}
          name={this.state.editedName}
          data={this.state.editedData}
        />
      </Flipper>
    );
  }
}

const mapStateToProps = (state) => {
  const config = state.config.get('data', Map());
  return {
    cams: state.cams.getIn(['data', 'PlusCams'], Map()).toJS(),
    plusLicenseStatus: config.getIn(['LicenseInfo', 'FOURSTREAM_PLUS', 'Status'], ''),
    panelsLimit: config.getIn(['LicenseInfo', 'FOURSTREAM_PLUS', 'PanelsLimit'], ''),
    isConfigReady: !!state.config.get('data') && !state.config.get('isFetchPending'),
    isReady: !!state.cams.get('data') && !state.cams.get('isFetchPending'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCams: () => dispatch(fetchCams()),
    fetchConfig: () => dispatch(fetchConfig()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlusPage);
