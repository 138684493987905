import React from "react";
import { FaCog } from 'react-icons/fa';
import './../styles/backarrow.scss'

class SettingsArrow extends React.Component {
  render() {
    return (
	  <a href="/settings" className="back-arrow p-2">
		<FaCog size="3em" />
	  </a>
    );
  }
}

export default SettingsArrow;
