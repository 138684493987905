// @flow

import React from 'react';
import type { Element } from 'react';
import { useSelector } from 'react-redux';
import * as Immutable from 'immutable';

export type NetworkInterfaceSelectProps = {
  value: string | null;
  onChange: (value: string | null) => *;
  className?: string;
}

export default function NetworkInterfaceSelect({
  value,
  onChange,
  className,
}: NetworkInterfaceSelectProps): Element<*> {
  const networkInterfaces = useSelector(
    state => state.config.getIn(['data', 'Ports'], Immutable.List()),
    Immutable.is,
  ).toJS();

  return (
    <select
      className={className}
      value={value || ''}
      onChange={e => {
        let v = e.target.value;
        onChange(v === 'none' ? null : v);
      }}
    >
      {networkInterfaces.length === 0
        ? <option value="none">...</option>
        : networkInterfaces.map(iface => (
          <option key={iface.name} value={iface.name}>
            {iface.name}
          </option>
        ))}
    </select>
  );
}
