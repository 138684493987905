import { actionType } from './cams';
import api from '../api';
import uiFetchThunk from '../api/ui';

export function pushCams(obj) {
  return {
    type: actionType.CAMS_PUSH,
    obj,
  };
}

export function fetchCams() {
  return uiFetchThunk(api.getCams, {
    loadingAction: actionType.CAMS_FETCH,
    successAction: actionType.CAMS_FETCH_SUCCESS,
    successArgs: cams => ({ cams }),
    failureAction: actionType.CAMS_FETCH_ERROR,
    failureDesc: 'fetch VestraView Essentials data',
    failureToastFilter: error => error.response.status !== 403,
  });
}

export function updateCam(newCam) {
  return uiFetchThunk(() => api.updateCam(newCam), {
    loadingAction: actionType.CAM_ADD_OR_UPDATE,
    successAction: actionType.CAM_ADD_OR_UPDATE_SUCCESS,
    successArgs: newCam => ({ 'PlusCams': newCam }),
    failureAction: actionType.CAM_ADD_OR_UPDATE_ERROR,
    successDesc: 'updated VestraView Essentials configuration',
    failureDesc: 'update VestraView Essentials configuration',
  });
}

export function addCam(newCam) {
  return uiFetchThunk(() => api.addCam(newCam), {
    loadingAction: actionType.CAM_ADD_OR_UPDATE,
    successAction: actionType.CAM_ADD_OR_UPDATE_SUCCESS,
    successArgs: newCam => ({ 'PlusCams': newCam }),
    failureAction: actionType.CAM_ADD_OR_UPDATE_ERROR,
    successDesc: 'updated VestraView Essentials configuration',
    failureDesc: 'update VestraView Essentials configuration',
  });
}

export function deleteCam(camName) {
  return uiFetchThunk(() => api.deleteCam({ 'CamName': camName }), {
    loadingAction: actionType.CAM_DELETE,
    successAction: actionType.CAM_DELETE_SUCCESS,
    successArgs: () => ({ camName }),
    failureAction: actionType.CAM_DELETE_ERROR,
    successDesc: 'deleted',
    failureDesc: 'delete camera configuration',
  });
}

export function moveCam(target, newLocation) {
  return uiFetchThunk(() => api.moveCam({ target: target, newLocation: newLocation }), {
    loadingAction: actionType.CAM_MOVE,
    successAction: actionType.CAM_MOVE_SUCCESS,
    failureAction: actionType.CAM_MOVE_ERROR,
    successDesc: 'moved',
    failureDesc: 'move panel',
  });
}
