import React from 'react';
import { Link } from 'react-router-dom';
import VidestraLogo from '../assets/Videstra Logo.png';
import VStreamerLogo from '../assets/V-Streamer_logo.png';
import VStreamerEssentialsLogo from "../assets/Essentials 3x1.png";
import ReloadButton from "./ReloadButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";

class MenuInner extends React.PureComponent {
  render() {
    return (
      <div style={styles.menu} className="col-sm-2 pt-2">
        <nav>
          <ul className="nav flex-column">
            {this.props.essentialsLicenseStatus === "valid" && (
              <li>
                <a className="nav-link" href="/">
                  Essentials
                </a>
              </li>
            )}
            <li>
              <Link className="nav-link" to="/Channels">
                Channels
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/VideoConfiguration">
                Video configuration
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Network">
                Network
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Security">
                Security
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Schedule">
                Schedule
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/System">
                System
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Reboot">
                Restart / Shutdown
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Status">
                Status
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/Diagnostics">
                Diagnostics
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/About">
                About
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const config = state.config.get("data", Map());
  return {
    essentialsLicenseStatus: config.getIn(["LicenseInfo", "FOURSTREAM_PLUS", "Status"])
  };
}

const Menu = connect(mapStateToProps)(MenuInner);

class AppLayout extends React.Component {
  render() {
    return (
      <div style={styles.root}>
        <div className="row" style={styles.header}>
          <div className="col-md-2 text-center">
            <img
              src={"/" + VidestraLogo}
              alt="Videstra"
              style={styles.videstra_logo}
              className="image-fluid m-1"
            />
          </div>
          <div className="col-md-10 text-center">
            <div className="container text-right">
              <img
                src={"/" + (this.props.settings ? VStreamerLogo : VStreamerEssentialsLogo)}
                alt="V Streamer"
                style={
                  this.props.settings ? styles.vstreamer_logo : styles.vstreamer_essentials_logo
                }
                className="image-fluid m-1"
              />
            </div>
          </div>
        </div>

        <div style={styles.main} className="row">
          {this.props.settings && <Menu />}
          <div id="page" className={this.props.settings ? "col-sm-10" : "col-sm-12"}>
            {this.props.children}
          </div>
        </div>
        {this.props.settings && <ReloadButton />}
      </div>
    );
  }
}

const styles = {
  root: {
    height: "100%",
    overflowX: "hidden",
  },
  header: {
    background: "black",
    overflow: "hidden",
  },
  videstra_logo: {
    height: 100,
  },
  vstreamer_logo: {
    height: 90,
    paddingTop: 10,
  },
  vstreamer_essentials_logo: {
    height: 100,
  },
  main: {
    height: "calc(100% - 108px)",
  },
  menu: {
    background: "#eee",
  },
  link: {
    padding: "10px 20px 10px 20px",
  },
};

AppLayout.propTypes = {
  settings: PropTypes.bool
};

AppLayout.defaultProps = {
  settings: true
};

export default AppLayout;
