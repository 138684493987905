import React from "react";
import api from "../api";

let currentInstanceRef = null;

class OfflineBanner extends React.Component {
  state = {
    networkStatus: "online",
    blockBanner: false
  };

  componentDidMount() {
    currentInstanceRef = this;
  }

  componentWillUnmount() {
    currentInstanceRef = null;
  }

  onRequestTimeout = () => {
    if (this.state.networkStatus == "online") {
      this.setState(
        {
          networkStatus: "check"
        },
        this.checkNetworkConnection
      );
    }
  };

  checkNetworkConnection = () => {
    Promise.all([api.getConfig(), api.getSecurity()])
      .catch(error => {
        console.dir(error);
        if (!error.response) {
          this.setState({ networkStatus: "offline" });
        }
      })
      .then(this.waitForReconnect);
  };

  waitForReconnect = () => {
    setInterval(() => {
      Promise.all([api.getConfig(), api.getSecurity()])
        .then(() => window.location.reload())
        .catch(error => {
          if (error.response) {
            window.location.reload();
          }
        });
    }, 5000);
  };

  block = () => {
    this.setState({ blockBanner: true });
  };

  render() {
    const { networkStatus, blockBanner } = this.state;
    if (networkStatus !== "offline" || blockBanner) {
      return null;
    }

    return (
      <div style={styles.overlay}>
        <div className="alert alert-danger m-5 p-4 text-center">
          <h4>V-Streamer is not responding!</h4>
        </div>
      </div>
    );
  }
}

const styles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000
  }
};

export function blockBanner() {
  if (currentInstanceRef) {
    currentInstanceRef.block();
  }
}

export function triggerOfflineBanner() {
  if (currentInstanceRef) {
    currentInstanceRef.onRequestTimeout();
  }
}

export default OfflineBanner;
