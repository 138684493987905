import 'bootstrap';
import './styles/main.scss'
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AppLayout from './components/AppLayout';
import OfflineBanner from './components/OfflineBanner';
import SettingsArrows from './components/SettingsArrow';
import PlusPage from './plus/index';
import { createStore } from './reducers/init';
import WebSocketsManager from './api/websockets';

const store = createStore();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <WebSocketsManager />
        <AppLayout settings={false}>
          <PlusPage />
          <SettingsArrows />
          <ToastContainer />
          <OfflineBanner />
        </AppLayout>
      </Provider>
    );
  }
}

export default App;

