import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { pushConfig } from '../reducers/configActions';
import { pushCams } from '../reducers/camsActions';
import { baseDomain, wsPort } from './config';

const websocketURL = `ws://${baseDomain}:${wsPort}`;

export default function WebSocketsManager(): React.Element<*> {
  const ws = useRef(null);
  const dispatch = useDispatch();

  const init = useCallback(() => {
    console.log('Starting WebSocket');
    const webSocketConn = new WebSocket(websocketURL);

    webSocketConn.onerror = function (event) {
      console.log('WebSocket error');
      console.log(event);
    };

    webSocketConn.onclose = function (event) {
      console.log('WebSocket connection closed');
      if (ws.current && ws.current.readyState === 3) {
        ws.current = null;
        setTimeout(() => {
          ws.current = init();
        }, 3000);
      }
    };

    webSocketConn.onmessage = function (event) {
      const obj = JSON.parse(event.data);
      if (obj['PlusCams']) {
        dispatch(pushCams(obj));
      } else {
        dispatch(pushConfig(obj));
      }
    };

    return webSocketConn;
  }, []);

  useEffect(() => {
    ws.current = init();

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, []);

  return <></>;
}
