/* @flow */

import React from 'react';
import type { Element } from 'react';

export type ModalProps = {
  isOpen: boolean,
  title: string,
  submitText: string,
  onClose: () => {},
  onSubmit: () => {},
  children: Element<*>,
};

export default function Modal({
  children,
  isOpen,
  onClose,
  onSubmit,
  submitText,
  title,
}: ModalProps): Element<*> {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="modal d-block" role="dialog" aria-hidden="false">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            {onSubmit && (
              <button type="button" className="btn btn-primary" onClick={onSubmit}>
                {submitText || 'Yes'}
              </button>
            )}
            {onClose && (
              <button type="button" className="btn btn-secondary" onClick={onClose}>
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
