import React from "react";
import './../styles/reloadbutton.scss'

class ReloadButton extends React.Component {
  reloadPage = () => {
    window.location.reload();
  }

  render() {
    return (
      <button className="btn btn-primary reloadbutton" onClick={this.reloadPage}>
        Reload page
      </button>
    );
  }
}

export default ReloadButton;
