// @flow

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCam } from '../reducers/camsActions';
import { updateConfig } from '../reducers/configActions';
import * as Immutable from 'immutable';
import { isEqual, uniq } from 'lodash';

const ALL_SDIS = ['Sdi0', 'Sdi1', 'Sdi2', 'Sdi3'];
const MULTIPLE_SELECTED = '$MULTIPLE_SELECTED';

export type OverlaySelectProps = {
  name: string,
  cam: Object,
  getChannelState: (string) => boolean,
  disabled?: boolean,
};

export default function OverlaySelect({
  name,
  cam,
  getChannelState,
  disabled,
}: OverlaySelectProps) {
  const dispatch = useDispatch();

  const overlayList = useSelector(
    state => state.config.getIn(['data', 'alphaimages'], Immutable.List()),
    Immutable.is,
  );

  const activeOverlays = useSelector(
    state => uniq(
      ALL_SDIS
        .filter(sdi => getChannelState(sdi) !== 'off')
        .map(sdi => state.config.getIn(['data', sdi, 'alphaimage'], 'none')),
    ),
    isEqual,
  );

  const onOverlayChange = useCallback(alphaimage => {
    if (alphaimage === MULTIPLE_SELECTED)
      return;

    dispatch(updateCam({ [name]: { ...cam, alphaimage } }));
    for (const sdi of ALL_SDIS) {
      if (getChannelState(sdi) !== 'off') {
        dispatch(updateConfig({ [sdi]: { alphaimage } }));
      }
    }
  }, [dispatch, name, cam, getChannelState]);

  const selectedOverlay = getSelectedOverlay(cam.alphaimage, activeOverlays);

  return (
    <select
      className="form-control m-2"
      onChange={e => onOverlayChange(e.target.value)}
      value={selectedOverlay}
      disabled={disabled}
    >
      {selectedOverlay === MULTIPLE_SELECTED && (
        <option
          key={MULTIPLE_SELECTED}
          value={MULTIPLE_SELECTED}
          disabled
        >
          (multiple selected)
        </option>
      )}
      <option key="none" value="none">no overlay</option>
      {overlayList.map(img => (
        <option key={img} value={img}>
          {img}
        </option>
      ))}
    </select>
  );
}

function getSelectedOverlay(camOverlay: string, activeOverlays: string[]): string {
  if (activeOverlays.length === 0)
    return camOverlay;

  if (activeOverlays.length === 1)
    return activeOverlays[0];

  return MULTIPLE_SELECTED;
}
