import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import Modal from '../components/Modal';
import { updateCam, addCam } from '../reducers/camsActions';
import NetworkInterfaceSelect from '../components/forms/NetworkInterfaceSelect';

type Props = {
  visible: Boolean,
  onCancel: Function,
  name: String,
  data: Object,
};

type State = {
  name: String,
  url: String,
  ctl_url: String,
  username: String,
  password: String,
  transport: String,
  port: String,
  errors: Array,
};

class AddCamForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      url: '',
      ctl_url: '',
      username: '',
      password: '',
      transport: 'TCP',
      port: '',
      errors: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (this.state.port == '' && this.props.networkInterfaces[0]) {
      this.setState({ port: this.props.networkInterfaces[0].name });
    }

    if (prevProps.name === null && this.props.name !== null) {
      this.setState({ name: this.props.name });
      this.setState(this.props.data);
    }
  }
  onNameChange = e => {
    this.setState({ name: e.target.value });
  };
  onUrlChange = e => {
    this.setState({ url: e.target.value });
  };
  onUrlCtlChange = e => {
    this.setState({ ctl_url: e.target.value });
  };
  onUsernameChange = e => {
    this.setState({ username: e.target.value });
  };
  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  onTransportChange = e => {
    this.setState({ transport: e.target.value });
  };
  onInterfaceChange = e => {
    this.setState({ port: e.target.value });
  };

  validateForm = () => {
    const errors = ['name', 'url'].reduce(this.nonEmptyString, []);

    if (this.props.name === null && this.state.name in this.props.cams) {
      errors.push('name');
    }

    this.setState({ errors: errors });
    return errors.length === 0;
  };

  nonEmptyString = (acc, key) => {
    const val = this.state[key];
    if (val && 0 !== val.trim().length) {
      return acc;
    } else {
      acc.push(key);
      return acc;
    }
  };

  resetState = () => {
    this.setState({
      name: '',
      url: '',
      ctl_url: '',
      username: '',
      password: '',
      transport: 'TCP',
      port: '',
      errors: [],
    });
  };

  addHttpPrefix = baseURL => {
    if (baseURL.length === 0 || baseURL.indexOf('://') != -1) {
      return baseURL;
    } else {
      return 'http://' + baseURL;
    }
  };

  onSubmit = () => {
    const { name, url, ctl_url, username, password, transport, port } = this.state;
    if (this.validateForm()) {
      const method = this.props.name ? this.props.updateCam : this.props.addCam;
      method({
        [name]: {
          url: url,
          ctl_url: this.addHttpPrefix(ctl_url),
          username: username,
          password: password,
          transport: transport,
          port: port,
          alphaimage: 'none',
        },
      });
      this.resetState();
      this.props.onCancel();
    }
  };

  onCancel = () => {
    this.props.onCancel();
    this.resetState();
  };
  render() {
    const errors = this.state.errors;
    return (
      <Modal
        isOpen={this.props.visible}
        title={this.props.name ? 'Edit camera' : 'Add camera'}
        onClose={this.onCancel}
        onSubmit={this.onSubmit}
        submitText="Save"
      >
        <form>
          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right">Name:</label>
            <div className="col-sm-9">
              <input
                type="text"
                className={'form-control ' + (errors.indexOf('name') > -1 ? 'is-invalid' : '')}
                onChange={this.onNameChange}
                value={this.state.name}
                disabled={this.props.name !== null}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right">URL:</label>
            <div className="col-sm-9">
              <input
                type="text"
                className={'form-control ' + (errors.indexOf('url') > -1 ? 'is-invalid' : '')}
                value={this.state.url}
                onChange={this.onUrlChange}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right">CTL URL:</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={this.state.ctl_url}
                onChange={this.onUrlCtlChange}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right">Username:</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={this.state.username}
                onChange={this.onUsernameChange}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right">Password:</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={this.state.password}
                onChange={this.onPasswordChange}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right" />
            <div className="col-sm-9">
              <select
                className="form-control"
                value={this.state.transport}
                onChange={this.onTransportChange}
              >
                <option value="TCP">TCP</option>
                <option value="UDP">UDP</option>
              </select>
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label className="col-sm-3 col-form-label text-right" />
            <div className="col-sm-9">
              <NetworkInterfaceSelect
                className="form-control"
                value={this.state.port}
                onChange={port => this.setState({ port })}
              />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    networkInterfaces: state.config.getIn(['data', 'Ports'], List()).toJS(),
    cams: state.cams.getIn(['data', 'PlusCams'], Map()).toJS(),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateCam: camSpec => dispatch(updateCam(camSpec)),
    addCam: camSpec => dispatch(addCam(camSpec)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCamForm);
