/* @flow */

import axios from 'axios';
import qs from 'qs';
import { triggerOfflineBanner, blockBanner } from '../components/OfflineBanner';
import { baseDomain, baseOrigin, baseProtocol, restPort } from './config';

const cppURL = `${baseProtocol}//${baseDomain}:${restPort}`;
const cgiURL = baseOrigin;

const cgiApi = axios.create({
  baseURL: cgiURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Pragma': 'no-cache',
  },
});

const cppApi = axios.create({
  baseURL: cppURL,
  headers: {
    'Pragma': 'no-cache',
  },
});

cgiApi.interceptors.response.use(
  request => {
    return request;
  },
  error => {
    console.dir(error);
    if (!error.response) {
      triggerOfflineBanner();
    }
    throw error;
  },
);

cppApi.interceptors.response.use(
  request => {
    return request;
  },
  error => {
    if (!error.response) {
      triggerOfflineBanner();
    }
    throw error;
  },
);

type SetNetwork = {
  type: string,
  iface: string,
  hostname: string,
  ip: string,
  netmask: string,
  gateway: string,
  dns: string
};

type UserAuth = {
  username: string,
  password: string
};

const uploadFile = (api, url, file, type) => {
  const form = new FormData();
  form.append('file', file);
  form.append('type', type);

  return api.post(url, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const api = {
  getNetwork: iface => {
    return cgiApi.get(`cgi/getNetwork.pl`);
  },
  updateNetwork: (body: SetNetwork) => {
    return cgiApi.post('cgi/setNetwork.pl', qs.stringify(body));
  },
  getSecurity: () => {
    return cgiApi.get('cgi/getSecurity.pl');
  },
  updateSecurity: (auth: UserAuth) => {
    return cgiApi.post('cgi/setSecurity.pl', qs.stringify(auth));
  },
  resetChannels: () => {
    return cgiApi.post('cgi/resetChannels.pl');
  },
  softRestart: () => {
    blockBanner();
    return cgiApi.post('cgi/restart.pl', qs.stringify({ soft: true }));
  },
  restart: () => {
    blockBanner();
    return cgiApi.post('cgi/restart.pl', qs.stringify({}));
  },
  shutdown: () => {
    return cgiApi.post('cgi/restart.pl', qs.stringify({ shutdown: true }));
  },
  getConfig: () => {
    return cppApi.get('');
  },
  getCams: () => {
    return cppApi.get('plus-cams');
  },
  updateCam: newCam => {
    return cppApi.put('plus-panel-update', newCam);
  },
  addCam: newCam => {
    return cppApi.put('plus-panel-add', newCam);
  },
  deleteCam: cam => {
    return cppApi.put('plus-panel-delete', cam);
  },
  moveCam: target => {
    return cppApi.put('plus-panel-move', target);
  },
  updateConfig: config => {
    return cppApi.put('', config);
  },
  uploadFirmware: file => {
    return uploadFile(cgiApi, 'cgi/upgradeFirmware.pl', file, 'firmware');
  },
  uploadTestpattern: file => {
    return uploadFile(cppApi, 'upload', file, 'testpattern');
  },
  uploadOverlay: file => {
    return uploadFile(cppApi, 'upload', file, 'overlay');
  },
  uploadLicense: file => {
    return uploadFile(cppApi, 'upload', file, 'license');
  },
  uploadPlusConfig: file => {
    return uploadFile(cppApi, 'upload', file, 'plusconfig');
  },
  downloadPlusConfig: () =>
    cppApi.get('plus-config').then(() => {
      window.location.href = `${cgiApi.defaults.baseURL}/download/plusconfig.ini`;
    }),
  initiateSyslogFile: () => cgiApi.post('cgi/getSyslog.pl', qs.stringify({ download: 1 })),
  getSyslogFileTimestamp: () => cgiApi.get('cgi/getSyslog.pl'),
  downloadSyslogFile: () => {
    window.location.href = `${cgiApi.defaults.baseURL}/download/syslog`;
  },
  statusApiUrl: () => cppApi.defaults.baseURL,
  middlemanStatus: () =>
    cgiApi.get('cgi/middleman.pl'),
  middlemanState: (state: 'on' | 'off') =>
    cgiApi.post('cgi/middleman.pl', qs.stringify({ state })),
  middlemanPort: (port: number) =>
    cgiApi.post('cgi/middleman.pl', qs.stringify({ port })),
};

export default api;
