import { Map } from 'immutable';
import * as _ from 'lodash';

export const actionType = {
  CAMS_FETCH: 'CAMS_FETCH',
  CAMS_FETCH_SUCCESS: 'CAMS_FETCH_SUCCESS',
  CAMS_FETCH_ERROR: 'CAMS_FETCH_ERROR',
  CAM_ADD_OR_UPDATE: 'CAM_ADD_OR_UPDATE',
  CAM_ADD_OR_UPDATE_SUCCESS: 'CAM_ADD_OR_UPDATE_SUCCESS',
  CAM_ADD_OR_UPDATE_ERROR: 'CAM_ADD_OR_UPDATE_ERROR',
  CAM_DELETE: 'CAM_DELETE',
  CAM_DELETE_SUCCESS: 'CAM_DELETE_SUCCESS',
  CAM_DELETE_ERROR: 'CAM_DELETE_ERROR',
  CAMS_PUSH: 'CAMS_PUSH',
  CAM_MOVE: 'CAM_MOVE',
  CAM_MOVE_ERROR: 'CAM_MOVE_ERROR',
  CAM_MOVE_SUCCESS: 'CAM_MOVE_SUCCESS',
};

const ACTION_HANDLERS = {
  [actionType.CAMS_FETCH]: (state, action) => {
    return state.merge({ isFetchPending: true });
  },
  [actionType.CAMS_FETCH_ERROR]: (state, action) => {
    return state.merge({
      isFetchPending: false,
      fetchErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.CAMS_FETCH_SUCCESS]: (state, action) => {
    return state.merge({
      isFetchPending: false,
      fetchErrors: null,
      data: action.cams,
    });
  },
  [actionType.CAMS_PUSH]: (state, action) => {
    return state.mergeIn(['data'], action.obj);
  },
  [actionType.CAM_ADD_OR_UPDATE_ERROR]: (state, action) => {
    return state.merge({
      updateErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.CAM_ADD_OR_UPDATE_SUCCESS]: (state, action) => {
    return state.merge({
      updateErrors: null,
    });
  },
  [actionType.CAM_DELETE_ERROR]: (state, action) => {
    return state.merge({
      updateErrors: {
        error: action.error,
        timeout: action.timeout,
      },
    });
  },
  [actionType.CAM_DELETE_SUCCESS]: (state, action) => {
    const newCams = _.remove(state.get('data').toJS(), action.camName);
    return state.merge({
      updateErrors: null,
    });
  },
};

const initialState = Map();
export const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
