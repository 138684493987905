import { actionType } from './config';
import api from '../api';
import uiFetchThunk from '../api/ui';

export function fetchConfig() {
  return uiFetchThunk(api.getConfig, {
    loadingAction: actionType.CONFIG_FETCH,
    successAction: actionType.CONFIG_FETCH_SUCCESS,
    successArgs: config => ({ config }),
    failureAction: actionType.CONFIG_FETCH_ERROR,
    failureDesc: 'fetch V-Streamer config',
  });
}

export function pushConfig(obj) {
  return {
    type: actionType.CONFIG_PUSH,
    obj
  };
}

export function updateConfig(config) {
  function scheduleFetch(dispatch) {
    setTimeout(() => dispatch(fetchConfig()), 100);
  }

  return uiFetchThunk(() => api.updateConfig(config), {
    loadingAction: actionType.CONFIG_UPDATE,
    successAction: actionType.CONFIG_UPDATE_SUCCESS,
    successArgs: () => ({ config }),
    failureAction: actionType.CONFIG_UPDATE_ERROR,
    successDesc: 'updated V-Streamer configuration',
    failureDesc: 'update V-Streamer configuration',
    onSuccess: scheduleFetch,
    onFailure: scheduleFetch,
  });
}

export function updateVideoConfiguration({ format, lineoffset, pixeloffset, reference }) {
  return (dispatch, getState) => {
    const config = getState().config.get('data').toJS();
    const outputs = ['Sdi0', 'Sdi1', 'Sdi2', 'Sdi3'];
    config.Reference = reference;
    outputs.forEach(item => {
      config[item].lineoffset = lineoffset;
      config[item].pixeloffset = pixeloffset;
      config[item].format = format
    });

    return dispatch(updateConfig(config));
  }
}

export function updateScheduleConfiguration({ timezone, scheduledInitialization, initializationEnabled }) {
  return (dispatch, getState) => {
    const prevConfig = getState().config.get('data').toJS();
    const config = {};

    if (prevConfig.TimeZone !== timezone) {
      config.TimeZone = timezone;
    }

    if (prevConfig.initializationEnabled !== initializationEnabled || prevConfig.scheduledInitialization !== scheduledInitialization) {
      config.InitializationEnabled = initializationEnabled;
      config.ScheduledInitialization = scheduledInitialization;
    }

    return dispatch(updateConfig(config));
  }
}
